import React, { useEffect } from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';

import { Routes, Route } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { get_info } from './store/authSlice';
import Navbar from './components/Navbar';
import Home from './screens/Home';
import Login from './screens/Login';
import Profile from './screens/Profile';
import Products from './screens/Products';
import AddProduct from './screens/AddProduct';
import EditProduct from './screens/EditProduct';
import Users from './screens/Users';
import EditProfile from './screens/EditProfile';
import Register from './screens/Register';

function App() {
  const dispatch = useDispatch();
  const access_token = Cookies.get('access_token');
  const { isLogged, isAdmin } = useSelector((state) => state.auth);
  useEffect(() => {
    if (access_token !== undefined) {
      dispatch(get_info(access_token));
    }
  }, [access_token])


  return (
    <ChakraProvider>
      <Flex
        flex={1}
        minH={'100vh'}
        width={'100%'}
        flexDir={'column'}
        alignItems={'center'}
      >
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path='/login' element={<Login />} />
          {isLogged && <Route path='/profile' element={<Profile />} />}

          {isAdmin && <Route path='/register' element={<Register />} />}
          {isAdmin && <Route path='/products' element={<Products />} />}
          {isAdmin && <Route path='/users' element={<Users />} />}
          {isAdmin && <Route path='/add-product' element={<AddProduct />} />}
          {isAdmin && <Route path='/edit-product/:id' element={<EditProduct />} />}
          {isAdmin && <Route path='/edit-profile/:username' element={<EditProfile />} />}





        </Routes>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
