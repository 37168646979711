import React, { useRef, useEffect } from 'react'
import { Flex, Input, Text, Button, useToast } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/authSlice';
export default function Login() {
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const toast = useToast();
    const dispatch = useDispatch();
    const { isLogged, wrongLogin, isLoading, respLogin } = useSelector((state) => state.auth)

    const handleLogin = () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        if (username === '' || password === '') {
            toast({
                title: 'Error',
                description: 'Please fill out all fields',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            return;
        }
        const data = { username, password }
        dispatch(login(data));
    }

    useEffect(() => {
        if (wrongLogin === true) {
            toast({
                title: 'Wrong username or password',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
        }
        else if (isLogged) {
            toast({
                title: 'Logged in successfully',
                status: 'success',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            const timer = setTimeout(() => {
                window.location.href = '/profile';
            }, 2000);
            return () => clearTimeout(timer);
        }

    }, [isLogged, wrongLogin])
    useEffect(() => {
        if (isLogged) {
            window.location.href = '/profile';
        }
    }, [isLogged])
    const handleKeyDownPassword = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };
    const handleKeyDownUsername = (e) => {
        if (e.key === 'Enter') {
            passwordRef.current.focus();
        }
    }
    return (
        <Flex
            width={{ base: '90%', md: '30%' }}
            height={'fit'}
            flexDir={'column'}
            bgColor={'#222831'}
            borderRadius={8}
            alignItems={'center'}
            pb={10}

        >
            <Text

                fontSize={32}
                width={'80%'}

                mt={8}
                fontWeight={'Bold'}
            >
                Login
            </Text>
            <Input
                placeholder='Username'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                width={'80%'}
                mt={6}
                ref={usernameRef}
                onKeyDown={handleKeyDownUsername}
            />
            <Input
                placeholder='Password'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                width={'80%'}
                mt={4}
                ref={passwordRef}
                type={'password'}
                onKeyDown={handleKeyDownPassword}
            />
            <Button
                colorScheme='teal'
                variant={'outline'}
                width={'80%'}
                mt={6}
                onClick={handleLogin}
            >
                Sign In
            </Button>



        </Flex>
    )
}
