import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box, Input, Text, Button, useToast, Sekleton, Skeleton } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function Profile() {
    const access_token = Cookies.get("access_token");
    const serialRef = useRef('');
    const [respSerial, setRespSerial] = useState(null)
    const [loadingSerial, setLoadingSerial] = useState(false)
    const navigate = useNavigate();
    const [resp, setResp] = useState(null)
    const toast = useToast();
    useEffect(() => {
        if (resp === null && access_token !== undefined) {
            fetch('https://api.zdx-storm.com/profile/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => setResp(data));

        }
    }, [resp, access_token])
    const handleLogout = () => {
        Cookies.remove('access_token');
        window.location.href = '/login'
    }
    const handleUpdateSerial = () => {
        setLoadingSerial(true);
        const serial = serialRef.current.value;
        console.log('serial: ' + serial)
        const data = { serial: serial }
        fetch('https://api.zdx-storm.com/update-serial/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status === 200) {
                toast({
                    title: 'Serial updated successfully',
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
            else {
                toast({
                    title: respSerial,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
            setLoadingSerial(false);
        })



    }

    function daysUntil(dateString) {
        // Parse the input date string
        const targetDate = new Date(dateString);
        // Get the current date
        const currentDate = new Date();

        // Calculate the difference in time (milliseconds)
        const timeDifference = targetDate - currentDate;

        // Convert time difference from milliseconds to days
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        return daysDifference;
    }
    return (
        <Flex
            width={{ base: '90%', md: '70%' }}
            height={'500px'}
            bg={'#222831'}
            borderRadius={8}
            flexDir={'column'}
            p={6}
        >

            <Flex width={'100%'} justifyContent={'space-between'}>
                <Flex justifyContent={'start'} alignItems={'center'} >
                    <Text fontSize={20} color={'#fff'}><Icon icon={'mdi:user-outline'} width={24} height={24} /></Text>
                    <Text fontSize={20} color={'#fff'} ml={2} fontWeight={'bold'}>{' '}{resp !== null ? resp.username : <Skeleton width={'100px'} height={'20px'} />}</Text>
                </Flex>
                <Flex sx={{
                    transform: 'scale(0.95)',
                    transition: 'box-shadow 0.5s, transform 0.5s',
                    '&:hover': {
                        transform: 'scale(1.3)',
                    },
                }} as={'button'}>
                    <Icon icon={'mdi:logout'} width={30} height={30} color='#fff' onClick={handleLogout} />
                </Flex>
            </Flex>
            <Flex
                flexDir={'column'}
                mt={6}
                mb={2}
                height={'300px'}
                overflowY={'scroll'}

            >
                {/* <Text fontSize={20} color={'#fff'} mb={2}>Products You Paid : </Text> */}

                {resp !== null && (
                    resp.products.map((item, index) => (
                        <Flex alignItems={'center'} mt={2} ml={2}>
                            <Text
                                fontSize={18}
                                _hover={{ color: 'silver' }}
                                as={'button'}
                                fontWeight={'bold'}
                                onClick={() => window.open(item.product.link_download, '_blank')}
                                textDecoration={'underline'}
                            >{item.product.name}</Text>
                            <Text ml={4} mr={4}>|</Text>
                            <Text
                                fontWeight={'bold'}
                                color={'lime'}
                            >{item.end_time_days} Days</Text>
                        </Flex>
                    ))
                )}

            </Flex>

            {resp !== null ? (
                <Input
                    placeholder='Serial'
                    bgColor={'#040D12'}
                    borderColor={'#040D12'}
                    width={{ base: '100%', md: '70%' }}
                    alignSelf={'center'}
                    mt={5}
                    color={'#fff'}
                    ref={serialRef}
                    defaultValue={resp.serial}
                />
            ) : (
                <Skeleton

                    width={{ base: '100%', md: '70%' }}
                    height={'30px'}
                    alignSelf={'center'}
                    mt={5}
                />
            )}
            <Button
                mt={5}
                width={'160px'}
                colorScheme='teal'
                alignSelf={'center'}
                _hover={{ opacity: 0.7 }}

                onClick={handleUpdateSerial}
            >
                {loadingSerial ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'Save'}
            </Button>
        </Flex>
    )
}
