import React, { useRef, useEffect } from 'react'
import { Flex, Input, Text, Button, useToast } from '@chakra-ui/react';
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../store/authSlice';
export default function Register() {
    const navigate = useNavigate();
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const toast = useToast();
    const dispatch = useDispatch();
    const { isLogged, wrongLogin, isLoading, respLogin, respRegister } = useSelector((state) => state.auth)

    const handleRegister = () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        if (username === '' || password === '') {
            toast({
                title: 'Error',
                description: 'Please fill out all fields',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
            return;
        }

        const data = { username, password }
        dispatch(register(data));
    }

    useEffect(() => {
        if (respRegister !== null) {
            if (respRegister.username === usernameRef.current.value) {
                navigate('/users');
            }
            else {
                toast({
                    title: respRegister,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
        }

    }, [respRegister])

    const handleKeyDownPassword = (e) => {
        if (e.key === 'Enter') {
            handleRegister();
        }
    };
    const handleKeyDownUsername = (e) => {
        if (e.key === 'Enter') {
            passwordRef.current.focus();
        }
    }
    return (
        <Flex
            width={{ base: '90%', md: '30%' }}
            height={'fit'}
            flexDir={'column'}
            bgColor={'#222831'}
            borderRadius={8}
            alignItems={'center'}
            pb={10}

        >
            <Text

                fontSize={32}
                width={'80%'}

                mt={8}
                fontWeight={'Bold'}
            >
                Register
            </Text>
            <Input
                placeholder='Username'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                width={'80%'}
                mt={6}
                ref={usernameRef}
                onKeyDown={handleKeyDownUsername}
            />
            <Input
                placeholder='Password'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                width={'80%'}
                mt={4}
                ref={passwordRef}
                type={'password'}
                onKeyDown={handleKeyDownPassword}
            />

            <Button
                colorScheme='teal'
                variant={'outline'}
                width={'80%'}
                mt={6}
                onClick={handleRegister}
            >
                Sign Up
            </Button>



        </Flex>
    )
}
