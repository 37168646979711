import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Input, Button, useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';
export default function EditProduct() {
    const { id } = useParams();
    const toast = useToast();
    const nameRef = useRef('');
    const linkRef = useRef('');
    const access_token = Cookies.get('access_token');

    const [resp, setResp] = useState(null);
    useEffect(() => {
        if (resp === null) {
            fetch(`https://api.zdx-storm.com/edit-product/${id}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    setResp(data);
                });
        }
    }, [resp])

    const handleEdit = () => {
        const name = nameRef.current.value;
        const link = linkRef.current.value;
        if (name === '') {
            toast({
                title: 'Error',
                description: 'Please enter name',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        const data = {
            name,
            link_download: link
        }
        fetch(`https://api.zdx-storm.com/edit-product/${id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        title: 'Success',
                        description: 'Product edit successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',

                    })
                    window.location.href = 'https://zdx-storm.com/products';
                } else {
                    toast({
                        title: 'Error',
                        description: response.statusText,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',
                    })
                }
            })
    }
    return (
        <Flex
            width={{ base: '90%', md: '50%' }}
            p={4}
            bgColor={'#222831'}
            borderRadius={8}
            flexDir={'column'}
            alignItems={'center'}

        >
            <Input
                placeholder='Name'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                ref={nameRef}
                defaultValue={resp !== null ? resp.name : ''}
            />
            <Input
                placeholder='Link Download'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                ref={linkRef}
                mt={4}
                defaultValue={resp !== null ? resp.link_download : ''}
            />
            <Button
                mt={8}
                colorScheme='teal'
                onClick={handleEdit}
            >Edit Product</Button>
        </Flex>
    )
}
