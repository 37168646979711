import React, { useState, useEffect } from 'react'
import { Flex, Box, Button, Text, Input } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
export default function Users() {
    const navigate = useNavigate();
    const [resp, setResp] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const access_token = Cookies.get('access_token');
    useEffect(() => {
        if (resp === null) {
            fetch('https://api.zdx-storm.com/users/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setResp(data.reverse())
                    setAllUsers(data)
                });
        }
    }, [resp])
    const handleSearch = (value) => {
        let filterUsers = allUsers.filter(word => word.username.toLowerCase().includes(value.toLowerCase()));
        setResp(filterUsers);
    }
    return (
        <Flex
            bgColor={'#222831'}
            //bgGradient={bk1}
            width={{ base: '90%', md: '40%' }}
            height={'500px'}
            borderRadius={8}
            flexDir={'column'}
        >
            <Flex width={'80%'} alignSelf={'center'} justifyContent={'space-between'}>
                <Input
                    placeholder='Search'
                    width={'100%'}
                    alignSelf={'center'}
                    onChange={(e) => handleSearch(e.target.value)}
                    mt={5}
                    mb={5}
                    bgColor={'#040D12'}
                    borderColor={'#040D12'}
                />


            </Flex>
            <Flex
                overflowY={'scroll'}
                width={'80%'}
                bgColor={'#040D12'}
                height={'400px'}
                alignSelf={'center'}
                borderRadius={2}
                borderTopLeftRadius={20}
                borderBottomRightRadius={20}
                flexDir={'column'}
                alignItems={'center'}
                pt={4}
            >
                {resp !== null && resp.map((item, index) => (
                    <Flex
                        bgColor={'#222831'}
                        width={'90%'}
                        height={'50px'}
                        m={1}
                        borderRadius={2}
                        borderTopLeftRadius={20}
                        borderBottomRightRadius={20}
                        alignItems={'center'}
                        p={2}
                        _hover={{ opacity: 0.7 }}
                        cursor={'pointer'}
                        justifyContent={'space-between'}
                        onClick={() => navigate(`/edit-profile/${item.username}`)}
                    >
                        <Flex>
                            <Text mr={1}>{item.id} - </Text>
                            <Text
                                fontSize={18}
                                fontWeight={'bold'}
                            >{item.username}</Text>
                        </Flex>

                    </Flex>
                ))}
            </Flex>
        </Flex>
    )
}
