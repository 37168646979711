import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
export default function Home() {
    const navigate = useNavigate();
    const { isLogged } = useSelector((state) => state.auth);
    useEffect(() => {
        if (isLogged) {
            navigate('/profile');
        }
        else {
            navigate('/login');
        }
    }, [])
    return (
        <div>

        </div>
    )
}
