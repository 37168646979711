import React from 'react'
import { Flex, Box, Text, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,

} from '@chakra-ui/react'
export default function Navbar() {
    const { isLogged, isAdmin } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    return (
        <Flex
            justifyContent={'space-between'}
            padding={6}
            width={'100%'}
        >
            <Text ml={4}>ZDX_</Text>
            <Flex>
                {!isLogged && !isAdmin ? (
                    <TextNav text={'Login'} path={'/login'} />
                ) : !isAdmin && <TextNav text={'Profile'} path={'/profile'} />}
                {isAdmin && (
                    <>
                        <Menu>
                            <MenuButton as={Button}>
                                Menu
                            </MenuButton>
                            <MenuList bgColor={'#222831'}>
                                <MenuItem
                                    bgColor={'#222831'}
                                    onClick={() => navigate('/products')}
                                    _hover={{ color: 'silver' }}
                                    fontWeight={'bold'}
                                >Products</MenuItem>
                                <MenuItem
                                    bgColor={'#222831'}
                                    onClick={() => navigate('/users')}
                                    _hover={{ color: 'silver' }}
                                    fontWeight={'bold'}
                                >Users</MenuItem>
                                <MenuItem
                                    bgColor={'#222831'}
                                    onClick={() => navigate('/register')}
                                    _hover={{ color: 'silver' }}
                                    fontWeight={'bold'}
                                >Register</MenuItem>
                                <MenuItem
                                    bgColor={'#222831'}
                                    onClick={() => navigate('/profile')}
                                    _hover={{ color: 'silver' }}
                                    fontWeight={'bold'}
                                >Profile</MenuItem>

                            </MenuList>
                        </Menu>
                        {/* <TextNav text={'Products'} path={'/products'} />
                        <TextNav text={'Users'} path={'/Users'} />
                        <TextNav text={'Register'} path={'/register'} /> */}
                    </>
                )}

            </Flex>
        </Flex>
    )
}
const TextNav = ({ text, path }) => {
    const navigate = useNavigate();
    return (
        <Text
            fontWeight={'bold'}
            onClick={() => navigate(path)}
            ml={3}
            textDecoration={'underline'}
            _hover={{ color: 'silver' }}
            cursor={'pointer'}
        >
            {text}
        </Text>
    )
}
