import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Input, Button, useToast } from '@chakra-ui/react'
import Cookies from 'js-cookie';
export default function AddProduct() {
    const toast = useToast();
    const nameRef = useRef('');
    const linkRef = useRef('');
    const access_token = Cookies.get('access_token');
    const handleAdd = () => {
        const name = nameRef.current.value;
        const link = linkRef.current.value;
        if (name === '') {
            toast({
                title: 'Error',
                description: 'Please enter name',
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        const data = {
            name,
            link_download: link
        }
        fetch('https://api.zdx-storm.com/add-product/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.status === 201) {
                    toast({
                        title: 'Success',
                        description: 'Product added successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',

                    })
                    window.location.href = 'https://zdx-storm.com/products';
                } else {
                    toast({
                        title: 'Error',
                        description: response.statusText,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        position: 'top',
                    })
                }
            })
    }
    return (
        <Flex
            width={{ base: '90%', md: '50%' }}
            p={4}
            bgColor={'#222831'}
            borderRadius={8}
            flexDir={'column'}
            alignItems={'center'}

        >
            <Input
                placeholder='Name'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                ref={nameRef}
            />
            <Input
                placeholder='Link Download'
                bgColor={'#040D12'}
                borderColor={'#040D12'}
                ref={linkRef}
                mt={4}
            />
            <Button
                mt={8}
                colorScheme='teal'
                onClick={handleAdd}
            >Add Product</Button>
        </Flex>
    )
}
