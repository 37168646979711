import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Input, Box, Button, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
export default function EditProfile() {
    const { username } = useParams();
    const toast = useToast();
    const [resp, setResp] = useState(null);
    const [products, setProducts] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [days, setDays] = useState('');
    const serialRef = useRef('');
    const handleChange = (e) => {
        setDays(e.target.value);
    }
    const access_token = Cookies.get('access_token');
    useEffect(() => {
        if (resp === null && username !== undefined) {
            fetch(`https://api.zdx-storm.com/profile/${username}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => setResp(data));
        }
        if (products === null) {
            fetch(`https://api.zdx-storm.com/products/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => setProducts(data));
        }
        if (resp !== null && products !== null && filtered === null) {
            const filtered = products.filter(product1 => {
                return !resp.products.some(product2 => product2.product.id === product1.id);
            });
            setFiltered(filtered);
        }
    }, [resp, products, filtered])
    const handleActive = (product_id) => {
        const data = {
            username,
            serial: serialRef.current.value,
            product_id,
            end_after: days
        }
        fetch('https://api.zdx-storm.com/update-profile/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        title: 'Success',
                        description: 'Product actived successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    return () => clearTimeout(timer);
                } else {
                    toast({
                        title: 'Error',
                        description: 'Failed to add product',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
    }
    const handleRemove = (profile_id, product_id) => {
        fetch(`https://api.zdx-storm.com/profile/remove_product/${profile_id}/${product_id}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        title: 'Success',
                        description: 'Product removed successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    return () => clearTimeout(timer);
                } else {
                    toast({
                        title: 'Error',
                        description: 'Failed to remove product',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
    }
    const handleSerial = () => {
        const data = {
            serial: serialRef.current.value
        }
        fetch(`https://api.zdx-storm.com/edit-serial/${resp.username}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                if (response.status === 200) {
                    toast({
                        title: 'Success',
                        description: 'Serial edited successfully',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                    return () => clearTimeout(timer);
                } else {
                    toast({
                        title: 'Error',
                        description: 'Failed to edit serial',
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            })
    }
    return (
        <Flex
            width={{ base: '90%', md: '50%' }}
            bgColor={'#222831'}
            //bgColor={'#040D12'}
            borderRadius={8}
            flexDir={'column'}
            height={'600px'}
            mt={4}
            alignItems={'center'}
            overflowY={'scroll'}
            pb={4}
        >
            <Flex width={'90%'} mt={3} justifyContent={'space-between'}>
                <Text fontSize={24} fontWeight={'bold'}>{username}</Text>
                <Input
                    ml={4}
                    placeholder='serial'
                    defaultValue={resp !== null ? resp.serial : ''}
                    ref={serialRef}
                    width={{ base: '40%', md: '50%' }}


                />
                <Button
                    colorScheme='green'
                    onClick={handleSerial}
                >Edit Serial</Button>
            </Flex>
            <Flex
                bgColor={'#040D12'}
                borderRadius={8}
                width={'90%'}
                mt={4}
                flexDir={'column'}
                alignItems={'center'}
                p={4}
                height={'300px'}
                overflowY={'scroll'}
            >
                <Text fontSize={24} fontWeight={'bold'}>UnActive Products</Text>
                {filtered !== null && filtered.map((item) => (
                    <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'} m={4} pb={4} borderBottomWidth={'1px'}>
                        <Text width={{ base: '40%', md: '70%' }}>{item.name}</Text>
                        <Input
                            placeholder='0'
                            width={{ base: '20%', md: '10%' }}
                            onChange={handleChange}

                        />
                        <Button
                            colorScheme='teal'
                            width={{ base: '24%', md: '16%' }}
                            onClick={() => handleActive(item.id)}
                        >Active</Button>
                    </Flex>
                ))}

            </Flex>
            <Flex
                bgColor={'#040D12'}
                borderRadius={8}
                width={'90%'}
                mt={4}
                flexDir={'column'}
                alignItems={'center'}
                p={4}
                height={'300px'}
                overflowY={'scroll'}
            >
                <Text fontSize={24} fontWeight={'bold'}>Activated</Text>
                {resp !== null && resp.products.map((item) => (
                    <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'} m={4} pb={4} borderBottomWidth={'1px'}>
                        <Text width={'70%'} fontWeight={'bold'}>{item.product.name}</Text>
                        <Flex width={'20%'}>
                            <Text display={{ base: 'none', md: 'block' }}>Days Left : </Text>
                            <Text fontWeight={'bold'} ml={1} color={'lime'}>{item.end_time_days}</Text>
                        </Flex>
                        <Button
                            colorScheme='red'
                            onClick={() => handleRemove(resp.username, item.product.id)}
                        >UnActive</Button>
                    </Flex>
                ))}

            </Flex>
        </Flex>
    )
}
